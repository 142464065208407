import { all, takeEvery, call, put, fork, takeLatest } from 'redux-saga/effects'
import fetchHelper, { deleteAuthToken, getAuthToken, setAuthToken } from 'library/FetchHelper'
import { CACHE_TOKEN, ROOT_API } from 'config/constants'
import actions from './actions'
// import profileActions from '../profile/actions'
import * as qs from 'query-string'
import Cookies from 'js-cookie'

function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield setAuthToken(payload.token)
  })
}
function loginFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/login`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload, cbs, cbe }) {
    try {
      const { data, status } = yield call(loginFromApi, payload, cbs, cbe)
      if (status === 200) {
        if (cbs) cbs(data)
        Cookies.set(CACHE_TOKEN, data.token)
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: data.token,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('loginRequest', error)
      if (cbe) cbe(error)
    }
  })
}

function registerFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/register`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* register({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(registerFromApi, payload)
    if (status === 200) {
      cbs(data.data)
    } else {
      cbe(data)
    }
  } catch (error) {
    cbe(error)
  }
}

function verifyCodeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/verify-email`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* verifyCode({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(verifyCodeFromApi, payload)
    if (status === 200) {
      cbs(data.data)
    } else {
      cbe(data)
    }
  } catch (error) {
    cbe(error)
  }
}

function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield deleteAuthToken()
  })
}
function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getAuthToken().get(CACHE_TOKEN)
    if (token) {
      // yield put({
      //   type: profileActions.GET_PROFILE,
      //   token,
      // })
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      })
    }
  })
}

function getListStatusFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/staticData/data?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListStatus() {
  yield takeEvery(actions.GET_LIST_STATUS, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListStatusFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_STATUS_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListStatus', error)
      if (cbe) cbe(error)
    }
  })
}

function getOTPFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/user/getOTP`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getOTP({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(getOTPFromApi, payload)
    if (status === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
  }
}

function forgetPassFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/user/forget-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* forgetPass({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(forgetPassFromApi, payload)
    if (status === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
  }
}

function changePasswordFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/user/change-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* changePassword({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(changePasswordFromApi, payload)
    if (status === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    takeLatest(actions.REGISTER, register),
    takeLatest(actions.VERIFY_CODE, verifyCode),
    takeLatest(actions.GET_OTP, getOTP),
    takeLatest(actions.FORGET_PASS, forgetPass),
    takeLatest(actions.CHANGE_PASS, changePassword),
    fork(logout),
    fork(getListStatus),
  ])
}
