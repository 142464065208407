import { all, takeEvery, call, put, fork, takeLatest } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'

function getChainsInfoFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/chains`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getChainsInfo({ payload }) {
  try {
    const { data, status } = yield call(getChainsInfoFromApi, payload)
    if (status === 200) {
      yield put(actions.getChainsInfoSuccess(data.data))
    } else {
      yield put(actions.getChainsInfoError(data))
    }
  } catch (error) {
    yield put(actions.getChainsInfoError(error))
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_CHAIN_INFO, getChainsInfo)])
}
