const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_ADMIN_WALLET_LIST: 'GET_ADMIN_WALLET_LIST',
  GET_ADMIN_WALLET_LIST_SUCCESS: 'GET_ADMIN_WALLET_LIST_SUCCESS',
  GET_ADMIN_WALLET_DETAIL: 'GET_ADMIN_WALLET_DETAIL',
  CREATE_WALLET_CHAIN: 'CREATE_WALLET_CHAIN',
  UPDATE_WALLET_CHAIN: 'UPDATE_WALLET_CHAIN',

  GET_ADMIN_WALLET_BALANCE: 'GET_ADMIN_WALLET_BALANCE',
  GET_ADMIN_WALLET_BALANCE_SUCCESS: 'GET_ADMIN_WALLET_BALANCE_SUCCESS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getAdminWalletList: (payload, cbs) => ({
    type: actions.GET_ADMIN_WALLET_LIST,
    payload,
    cbs,
  }),
  getAdminWalletListSuccess: (payload) => ({
    type: actions.GET_ADMIN_WALLET_LIST_SUCCESS,
    payload,
  }),

  DELETE_WALLET: 'DELETE_WALLET',
  deleteWallet: (payload, cbs, cbe) => ({
    type: actions.DELETE_WALLET,
    payload,
    cbs,
    cbe,
  }),

  CREATE_WALLET: 'CREATE_WALLET',
  createWallet: (payload, cbs, cbe) => ({
    type: actions.CREATE_WALLET,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
