import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListUserFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_USER_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListUser', error)
      if (cbe) cbe(error)
    }
  })
}

function createUserFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createUser() {
  yield takeEvery(actions.CREATE_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createUser', error)
      if (cbe) cbe(error)
    }
  })
}

function updateUserFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/update`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateUser() {
  yield takeEvery(actions.UPDATE_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(updateUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('updateUser', error)
      if (cbe) cbe(error)
    }
  })
}

function detailUserFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* detailUser() {
  yield takeEvery(actions.DETAIL_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(detailUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('detailUser', error)
      if (cbe) cbe(error)
    }
  })
}

function deleteUserFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteUser() {
  yield takeEvery(actions.DELETE_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(deleteUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('deleteUser', error)
      if (cbe) cbe(error)
    }
  })
}

function getProfileFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/profile`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getProfile() {
  yield takeEvery(actions.GET_PROFILE, function* ({ payload, cbs, cbe }) {
    try {
      // const { data } = yield call(getProfileFromApi, payload, cbs, cbe)
      // if (data.code === 200) {
      //   if (cbs) cbs(data.data)
      //   yield put({
      //     type: actions.GET_PROFILE_SUCCESS,
      //     data: data.data,
      //   })
      // } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getProfile', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getListUser), fork(createUser), fork(updateUser), fork(detailUser), fork(deleteUser), fork(getProfile)])
}
