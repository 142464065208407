import actions from './actions'

const initState = { wallets: [] }

export default function walletReducer(state = initState, action) {
  const { payload } = action
  switch (action.type) {
    case actions.GET_ADMIN_WALLET_LIST_SUCCESS:
      return {
        ...state,
        wallets: payload,
      }
    default:
      return state
  }
}
