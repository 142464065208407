const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_USER: 'GET_LIST_USER',
  GET_LIST_USER_SUCCESS: 'GET_LIST_USER_SUCCESS',
  CREATE_USER: 'CREATE_USER',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  DETAIL_USER: 'DETAIL_USER',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListUser: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_USER,
    payload,
    cbs,
    cbe,
  }),

  getListUserSuccess: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_USER_SUCCESS,
    payload,
    cbs,
    cbe,
  }),

  createUser: (payload, cbs, cbe) => ({
    type: actions.CREATE_USER,
    payload,
    cbs,
    cbe,
  }),

  updateUser: (payload, cbs, cbe) => ({
    type: actions.UPDATE_USER,
    payload,
    cbs,
    cbe,
  }),

  deleteUser: (payload, cbs, cbe) => ({
    type: actions.DELETE_USER,
    payload,
    cbs,
    cbe,
  }),

  detailUser: (payload, cbs, cbe) => ({
    type: actions.DETAIL_USER,
    payload,
    cbs,
    cbe,
  }),

  getListProfile: (payload, cbs, cbe) => ({
    type: actions.GET_PROFILE,
    payload,
    cbs,
    cbe,
  }),

  getListProfileSuccess: (payload, cbs, cbe) => ({
    type: actions.GET_PROFILE_SUCCESS,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
