import { combineReducers } from 'redux'
import App from 'redux/app/reducer'
import Auth from 'redux/auth/reducer'
import User from 'redux/user/reducer'
import Customer from 'redux/customer/reducer'
import Transaction from 'redux/transaction/reducer'
import Wallet from 'redux/wallet/reducer'
import Chains from 'redux/chains/reducer'
import ThemeSwitcher from 'redux/themeSwitcher/reducer'
import LanguageSwitcher from 'redux/languageSwitcher/reducer'

export default combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Auth,
  User,
  Customer,
  Wallet,
  Chains,
  Transaction,
})
