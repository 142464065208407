import { all } from 'redux-saga/effects'
import authSagas from 'redux/auth/saga'
import userSaSga from 'redux/user/saga'
import customerSaSga from 'redux/customer/saga'
import walletSaSga from 'redux/wallet/saga'
import chainsSaga from 'redux/chains/saga'
import transactionSaSga from 'redux/transaction/saga'

export default function* rootSaga(getState) {
  yield all([authSagas(), userSaSga(), customerSaSga(), chainsSaga(), walletSaSga(), transactionSaSga()])
}
