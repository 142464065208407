import { all, takeEvery, takeLatest, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getTransactionsFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/transaction?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getTransactions({ payload }) {
  try {
    const { data, status } = yield call(getTransactionsFromApi, payload)
    if (status === 200) {
      yield put(actions.getTransactionsSuccess(data.data))
    }
  } catch (error) {
    console.error('getTransactions', error)
  }
}

function createTransactionsFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/transaction`, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createTransactions({ payload, cbs, cbe }) {
  try {
    const { data, status } = yield call(createTransactionsFromApi, payload)
    if (status === 200) {
      cbs(data.data)
    } else {
      cbe(data)
    }
  } catch (error) {
    console.error('createTransactions', error)
    cbe(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST_TRANSACTION, getTransactions),
    takeLatest(actions.CREATE_TRANSACTION, createTransactions),
  ])
}
