import actions from './actions'

const initState = { chains: [], loading: true, message: '' }

export default function authReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case actions.GET_CHAIN_INFO:
      return {
        ...state,
        loading: true,
      }
    case actions.GET_CHAIN_INFO_SUCCESS:
      return {
        ...state,
        chains: payload,
        loading: false,
        message: '',
      }
    case actions.GET_CHAIN_INFO_ERROR:
      return {
        ...state,
        loading: false,
        message: payload.message || 'Error',
      }
    default:
      return state
  }
}
