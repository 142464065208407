const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_LIST_STATUS: 'GET_LIST_STATUS',
  GET_LIST_STATUS_SUCCESS: 'GET_LIST_STATUS_SUCCESS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload, cbs, cbe) => ({
    type: actions.LOGIN_REQUEST,
    payload,
    cbs,
    cbe,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),

  getListStatus: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_STATUS,
    payload,
    cbs,
    cbe,
  }),

  REGISTER: 'REGISTER',
  register: (payload, cbs, cbe) => ({
    type: actions.REGISTER,
    payload,
    cbs,
    cbe,
  }),
  VERIFY_CODE: 'VERIFY_CODE',
  verifyCode: (payload, cbs, cbe) => ({
    type: actions.VERIFY_CODE,
    payload,
    cbs,
    cbe,
  }),


  GET_OTP: 'GET_OTP',
  getOtp: (payload, cbs, cbe) => ({
    type: actions.GET_OTP,
    payload,
    cbs,
    cbe,
  }),

  FORGET_PASS: 'FORGET_PASS',
  forgetPass: (payload, cbs, cbe) => ({
    type: actions.FORGET_PASS,
    payload,
    cbs,
    cbe,
  }),

  CHANGE_PASS: 'CHANGE_PASS',
  changePassword: (payload, cbs, cbe) => ({
    type: actions.CHANGE_PASS,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
