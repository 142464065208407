const actions = {
  GET_CHAIN_INFO: 'GET_CHAIN_INFO',
  GET_CHAIN_INFO_SUCCESS: 'GET_CHAIN_INFO_SUCCESS',
  GET_CHAIN_INFO_ERROR: 'GET_CHAIN_INFO_ERROR',
  getChainsInfo: (payload) => ({
    type: actions.GET_CHAIN_INFO,
    payload,
  }),
  getChainsInfoSuccess: (payload) => ({
    type: actions.GET_CHAIN_INFO_SUCCESS,
    payload,
  }),
  getChainsInfoError: (payload) => ({
    type: actions.GET_CHAIN_INFO_ERROR,
    payload,
  }),
}
export default actions
