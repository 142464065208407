const actions = {
  GET_LIST_TRANSACTION: 'GET_LIST_TRANSACTION',
  GET_LIST_TRANSACTION_SUCCESS: 'GET_LIST_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  getTransactions: (payload) => ({
    type: actions.GET_LIST_TRANSACTION,
    payload,
  }),
  getTransactionsSuccess: (payload) => ({
    type: actions.GET_LIST_TRANSACTION_SUCCESS,
    payload,
  }),
  createTransaction: (payload, cbs, cbe) => ({
    type: actions.CREATE_TRANSACTION,
    payload,
    cbs, cbe
  })
}
export default actions
