export const PUBLIC_ROUTE = {
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  VERIFY_CODE: '/verify-code',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
}

export const PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  WALLET: '/wallet',
  WALLET_CREATE: '/wallet/create',
  TRANSACTIONS: "/transactions",
  TRANSACTIONS_CREATE: '/transactions/create',
  CHAINS: '/chains'
}
