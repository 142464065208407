import actions from './actions'

const initState = { transactions: [], loading: true }

export default function authReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case actions.GET_LIST_TRANSACTION:
      return {
        ...state,
        loading: false
      }
    case actions.GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: payload,
        loading: false
      }
    default:
      return state
  }
}
