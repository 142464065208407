import { all, takeEvery, call, put, fork, takeLatest } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions' 
import * as qs from 'query-string'

function getWalletChainFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/wallet/get?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getAdminWalletList() {
  yield takeEvery(actions.GET_ADMIN_WALLET_LIST, function* ({ payload, cbs }) {
    try {
      const { data, status } = yield call(getWalletChainFromApi, payload)
      if (status === 200) {
        yield put(actions.getAdminWalletListSuccess(data.data))
        if (cbs) cbs(data.data)
      }
    } catch (error) {
      console.error('getAdminWalletList', error)
    }
  })
}

function getDetailAdminWalletFromApi(payload) {
  const qStr = qs.stringify(payload)

  return fetchHelper
    .fetch(`${ROOT_API}/admin/chainWallet/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getDetailAdminWallet() {
  yield takeEvery(actions.GET_ADMIN_WALLET_DETAIL, function* ({ payload, cbs }) {
    try {
      const { data } = yield call(getDetailAdminWalletFromApi, payload)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      }
    } catch (error) {
      console.error('getDetailAdminWallet', error)
      if (cbs) cbs(error)
    }
  })
}

function createWalletFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/wallet/create`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createWallet({ cbs, cbe }) {
    try {
      const { data, status } = yield call(createWalletFromApi)
      if (status === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createWallet', error)
      if (cbe) cbe(error)
    }
}

function deleteWalletFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/wallet/delete`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteWallet() {
  yield takeEvery(actions.DELETE_WALLET, function* ({ payload, cbs, cbe }) {
    try {
      const { data, status } = yield call(deleteWalletFromApi, payload, cbs, cbe)
      if (status === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('deleteWallet', error)
      if (cbe) cbe(error)
    }
  })
}



export default function* rootSaga() {
  yield all([
    fork(getAdminWalletList),
    fork(deleteWallet),
    takeLatest(actions.CREATE_WALLET, createWallet)
  ])
}
